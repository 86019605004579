import React from "react";
import { Link as GatsbyLink } from "gatsby";
import h1No from "../assets/a11yChecklist/headings/h1No.png";
import h1Yes from "../assets/a11yChecklist/headings/h1Yes.png";
import hierarchyNo from "../assets/a11yChecklist/headings/hierarchyNo.png";
import hierarchyYes from "../assets/a11yChecklist/headings/hierarchyYes.png";
import lookalikeNo from "../assets/a11yChecklist/headings/lookalikeNo.png";
import lookalikeYes from "../assets/a11yChecklist/headings/lookalikeYes.png";
import nonHeadingNo from "../assets/a11yChecklist/headings/nonHeadingNo.png";
import nonHeadingYes from "../assets/a11yChecklist/headings/nonHeadingYes.png";
import componentsOrTextStylesNo from "../assets/a11yChecklist/headings/componentsOrTextStylesNo.png";
import componentsOrTextStylesYes from "../assets/a11yChecklist/headings/componentsOrTextStylesYes.png";
import colorRelianceYes from "../assets/a11yChecklist/color/colorRelianceYes.png";
import colorRelianceNo from "../assets/a11yChecklist/color/colorRelianceNo.png";
import textYes from "../assets/a11yChecklist/color/textYes.png";
import textNo from "../assets/a11yChecklist/color/textNo.png";
import graphicElementsYes from "../assets/a11yChecklist/color/graphicElementsYes.png";
import graphicElementsNo from "../assets/a11yChecklist/color/graphicElementsNo.png";
import interactiveElementsYes from "../assets/a11yChecklist/color/interactiveElementsYes.png";
import interactiveElementsNo from "../assets/a11yChecklist/color/interactiveElementsNo.png";
import pluginYes from "../assets/a11yChecklist/color/pluginYes.png";
import pluginNo from "../assets/a11yChecklist/color/pluginNo.png";
import orderYes from "../assets/a11yChecklist/order and connection/orderYes.png";
import orderNo from "../assets/a11yChecklist/order and connection/orderNo.png";
import importantInfoAndActionsYes from "../assets/a11yChecklist/order and connection/importantInfoAndActionsYes.png";
import importantInfoAndActionsNo from "../assets/a11yChecklist/order and connection/importantInfoAndActionsNo.png";
import connectionYes from "../assets/a11yChecklist/order and connection/connectionYes.png";
import connectionNo from "../assets/a11yChecklist/order and connection/connectionNo.png";
import columnHeaderYes from "../assets/a11yChecklist/tables/columnHeaderYes.png";
import columnHeaderNo from "../assets/a11yChecklist/tables/columnHeaderNo.png";
import interactiveContentYes from "../assets/a11yChecklist/tables/interactiveContentYes.png";
import interactiveContentNo from "../assets/a11yChecklist/tables/interactiveContentNo.png";
import noDataYes from "../assets/a11yChecklist/tables/noDataYes.png";
import noDataNo from "../assets/a11yChecklist/tables/noDataNo.png";
import staticContentYes from "../assets/a11yChecklist/tables/staticContentYes.png";
import staticContentNo from "../assets/a11yChecklist/tables/staticContentNo.png";
import multiplePagesYes from "../assets/a11yChecklist/forms/multiplePagesYes.png";
import multiplePagesNo from "../assets/a11yChecklist/forms/multiplePagesNo.png";
import placeholdersYes from "../assets/a11yChecklist/forms/placeholdersYes.png";
import placeholdersNo from "../assets/a11yChecklist/forms/placeholdersNo.png";
import requiredFormatYes from "../assets/a11yChecklist/forms/requiredFormatYes.png";
import requiredFormatNo from "../assets/a11yChecklist/forms/requiredFormatNo.png";
import visibleLabelsYes from "../assets/a11yChecklist/forms/visibleLabelsYes.png";
import visibleLabelsNo from "../assets/a11yChecklist/forms/visibleLabelsNo.png";
import generalUsageYes from "../assets/a11yChecklist/icons/generalUsageYes.png";
import generalUsageNo from "../assets/a11yChecklist/icons/generalUsageNo.png";
import tooltipYes from "../assets/a11yChecklist/icons/tooltipYes.png";
import tooltipNo from "../assets/a11yChecklist/icons/tooltipNo.png";
import labelsYes from "../assets/a11yChecklist/icons/labelsYes.png";
import labelsNo from "../assets/a11yChecklist/icons/labelsNo.png";
import flashingAnimationsYes from "../assets/a11yChecklist/animations/flashingAnimationsYes.png";
import flashingAnimationsNo from "../assets/a11yChecklist/animations/flashingAnimationsNo.png";
import staticAlternativesYes from "../assets/a11yChecklist/animations/staticAlternativesYes.png";
import staticAlternativesNo from "../assets/a11yChecklist/animations/staticAlternativesNo.png";
import controlsYes from "../assets/a11yChecklist/animations/controlsYes.png";
import controlsNo from "../assets/a11yChecklist/animations/controlsNo.png";

import { H3, Paragraph, TextContainer } from "../../lib/src";
import { DocPageMeta, HeaderSection, ContentSection, Code, Header } from "../docs";
import { DoDont } from "../docs/docs-do-dont";
import { Accordion } from "../docs/docs-accordion";

export const Meta: DocPageMeta = {
	category: "Designing",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Accessibility checklist for designers"
			subTitle="Use this checklist to avoid common accessibility issues in your UI designs."
		/>
		<ContentSection>
			<TextContainer article>
				<Header.H2>What is this?</Header.H2>
				<Paragraph>
					This is a checklist that Siteimprove designers can use to check whether their UI designs
					comply with a subset of{" "}
					<a href="https://www.w3.org/WAI/standards-guidelines/wcag/">WCAG 2.1 (level AA)</a>{" "}
					guidelines related to UI design. Please reach out to us on Slack in the{" "}
					<a href="https://siteimprove.slack.com/archives/CAVM2RC4F">#ux_fancy</a> channel if you
					have any questions or feedback.
				</Paragraph>
				<Header.H2>Does the checklist guarantee an accessible design?</Header.H2>
				<Paragraph>
					No, but it's a solid start and you’ll have avoided a lot of common issues. To avoid other
					potential accessibility issues, we recommend you also consult the accessibility guidelines
					of the Fancy components you use in your designs. Lastly, when in doubt about anything
					accessibility related, don't hesitate to reach out to Fancy's accessibility experts
					through the <a href="https://siteimprove.slack.com/archives/C02B3J1LL6S">#a11y_fancy</a>{" "}
					channel.
				</Paragraph>
				<Header.H2>Checklist</Header.H2>
				<Accordion headingLevel={H3}>
					<Accordion.Section title="Headings" fullWidth>
						<Header.H4>Hierarchy</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: hierarchyYes,
									alt: "Heading structure with one level h1 heading and correctly nested headings level h2 and h3",
								}}
							>
								<Paragraph>
									Use <GatsbyLink to="/lib/components/Text/Heading">Headings</GatsbyLink> to create
									a clear content hierarchy and help screen reader users navigate a page's content.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: hierarchyNo,
									alt: "Heading structure where levels are skipped, going directly from level h2 to h4.",
								}}
							>
								<Paragraph>
									Skip <GatsbyLink to="/lib/components/Text/Heading">Heading</GatsbyLink> levels.
								</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>H1 level</Header.H4>
						<DoDont.Layout>
							<DoDont.Do
								exampleImage={{
									src: h1Yes,
									alt: "Page with only one h1 level heading.",
								}}
							>
								<Paragraph>
									Provide every page with an <Code>h1</Code> level{" "}
									<GatsbyLink to="/lib/components/Text/Heading">Heading</GatsbyLink>.
								</Paragraph>
							</DoDont.Do>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: h1No,
									alt: "Page with multiple h1 level headings.",
								}}
							>
								<Paragraph>
									Use more than one <Code>h1</Code> level{" "}
									<GatsbyLink to="/lib/components/Text/Heading">Heading</GatsbyLink> per page.
								</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Component or text styles</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: componentsOrTextStylesYes,
									alt: "Screenshot of Fancy UI kit, where the predefined text styling is used.",
								}}
							>
								<Paragraph>
									Use the text components or styles in the{" "}
									<GatsbyLink to="/Designing/figma-FAQ">Fancy UI kit for Figma</GatsbyLink>. This
									enables developers to inspect your designs and figure out the correct{" "}
									<GatsbyLink to="/lib/components/Text/Heading">Heading</GatsbyLink> level to use in
									code.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: componentsOrTextStylesNo,
									alt: "Screenshot of text being styled manually instead of with predefined styles.",
								}}
							>
								<Paragraph>
									Style <GatsbyLink to="/lib/components/Text/Heading">Heading</GatsbyLink> level
									text manually (by selecting the font, font-size, line-height, etc.).
								</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Styling non-heading text</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: nonHeadingYes,
									alt: 'Code block used to correctly style non-heading text: <InlineText size="xxxLarge" emphasis="strong">104</InlineText><InlineText size="large">issues</InlineText>',
								}}
							>
								<Paragraph>
									Use <GatsbyLink to="/lib/components/Text/Inline text">InlineText</GatsbyLink> or{" "}
									<GatsbyLink to="/lib/components/Text/Paragraph">Paragraph</GatsbyLink> components
									(text styles in <GatsbyLink to="/Designing/figma-FAQ">Figma</GatsbyLink>) and
									their <Code>size</Code> and <Code>emphasis</Code> properties to style text that
									doesn’t function as a{" "}
									<GatsbyLink to="/lib/components/Text/Heading">Heading</GatsbyLink>.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: nonHeadingNo,
									alt: "Semantic heading tags used to style non-heading text.",
								}}
							>
								<Paragraph>
									Use <GatsbyLink to="/lib/components/Text/Heading">Headings</GatsbyLink> to style
									text that doesn’t function as a heading.
								</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Lookalike</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: lookalikeYes,
									alt: 'Code block used to correctly style headings: <H1 lookalike="h2">Accessibility<H1><H2 lookalike="h3">Potential issues</H2>',
								}}
							>
								<Paragraph>
									Be aware that the{" "}
									<GatsbyLink to="/lib/components/Text/Heading">Heading</GatsbyLink> component has a{" "}
									<Code>lookalike</Code> property that makes headings look like different heading
									levels. Annotate usage of this property in your designs, so it's clear for
									developers.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: lookalikeNo,
									alt: 'Code block used to incorrectly style headings so it skips levels: <H1 lookalike="h2">Accessibility</H1><H2 lookalike="h5">Potential issues</H2>',
								}}
							>
								<Paragraph>
									Skip <GatsbyLink to="/lib/components/Text/Heading">Heading</GatsbyLink> levels in
									your designs, when making use of the <Code>lookalike</Code> property.
								</Paragraph>
							</DoDont>
						</DoDont.Layout>
					</Accordion.Section>
					<Accordion.Section title="Colors" fullWidth>
						<Header.H4>Color reliance</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: colorRelianceYes,
									alt: "Input field in an invalid state indicated through use of color, a descriptive label and an accompanying icon. The error message is easy to understand.",
								}}
							>
								<Paragraph>
									Add a descriptive label and/or graphic element, when color is used to convey
									something.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: colorRelianceNo,
									alt: "Input field in an invalid state indicated only through its border color. The error message is vague and harder to understand.",
								}}
							>
								<Paragraph>Rely on color alone to convey something.</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Text</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: textYes,
									alt: "Text with a contrast ratio above 4.5:1. The text is easy to read.",
								}}
							>
								<Paragraph>Make sure text has a contrast ratio of at least 4.5:1.</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: textNo,
									alt: "Text with a contrast ratio below 4.5:1. The text is difficult to read.",
								}}
							>
								<Paragraph>Use a contrast ratio below 4.5:1 for text.</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Graphic elements</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: graphicElementsYes,
									alt: "Siteimprove logo with a contrast ratio above 3:1. The logo is easy to see.",
								}}
							>
								<Paragraph>
									Make sure the parts of a graphic element that are necessary to understand it, have
									a contrast ratio of 3:1 — both against their background and adjacent parts.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: graphicElementsNo,
									alt: "Siteimprove logo with a contrast ratio below 3:1. The logo is difficult to see.",
								}}
							>
								<Paragraph>Use a contrast ratio below 3:1 for graphic elements.</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Interactive elements</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: interactiveElementsYes,
									alt: "Button border with a contrast ratio above 3:1. The button is easy to see.",
								}}
							>
								<Paragraph>
									Make sure interactive elements have a contrast ratio of at least 3:1.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: interactiveElementsNo,
									alt: "Button border with a contrast ratio below 3:1. The button is difficult to see.",
								}}
							>
								<Paragraph>Use a contrast ratio below 3:1 for interactive elements.</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Plugin</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: pluginYes,
									alt: "Screenshot of Contrast, a Figma plugin for checking contrast ratios. It shows you two colors failing against each other.",
								}}
							>
								<Paragraph>
									Use the pre-installed “Contrast” Figma plugin to check contrast ratios in your
									designs. Especially when deviating from the default{" "}
									<GatsbyLink to="/design-tokens/#border-colors">border</GatsbyLink>,{" "}
									<GatsbyLink to="/design-tokens/#background-colors">background</GatsbyLink>, and{" "}
									<GatsbyLink to="/design-tokens/#text-colors">text</GatsbyLink> colors.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: pluginNo,
									alt: "Screenshot of a Figma plugin showing that Fancy buttons satisfy contrast requirements. It shows you two colors passing against each other.",
								}}
							>
								<Paragraph>
									Worry about color contrast between the internal parts that make up Fancy
									components. They’ve been checked and pass the WCAG AA level.
								</Paragraph>
							</DoDont>
						</DoDont.Layout>
					</Accordion.Section>
					<Accordion.Section title="Order and connection" fullWidth>
						<Header.H4>Order</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: orderYes,
									alt: "A page with various UI elements on it, stacked both horizontally and vertically. Arrows between the elements show how screen reader users experience the page in a linear top-to-bottom left-to-right order.",
								}}
							>
								<Paragraph>
									Keep in mind that screen reader uses will generally experience your design in a
									linear top-to-bottom, left-to-right order.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: orderNo,
									alt: "A page with various UI elements on it, stacked both horizontally and vertically. A visually prominent element is placed in the bottom right corner. Arrows between the elements show how this element is the last one a screen reader user becomes aware of.",
								}}
							>
								<Paragraph>
									Assume all users will immediately notice visually prominent UI elements,
									regardless of their position on a page.
								</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Important information and actions</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: importantInfoAndActionsYes,
									alt: "An important message shown towards the top of a page, before the elements it concerns. Also, buttons inside the message are preceded by information about the button actions.",
								}}
							>
								<Paragraph>
									Place important information and actions before elements they concern.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: importantInfoAndActionsNo,
									alt: "An important message shown at the bottom of a page, after the elements it concerns. Also, buttons inside the message precede information about the button actions.",
								}}
							>
								<Paragraph>
									Place important information and actions after elements they concern.
								</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Connection</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: connectionYes,
									alt: "A page with a section containing a table and a button to create new table rows.  The first table row is highlighted to indicate that it’s newly created. It’s placed immediately after the button.",
								}}
							>
								<Paragraph>
									Try to place new content immediately after the element that triggered its
									creation.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: connectionNo,
									alt: "A page with several sections and a button to create new sections. A newly created section is not placed immediately after the button. Several other sections are placed between the button and the new section.",
								}}
							>
								<Paragraph>
									Hesitate to reach out to an accessibility specialist when this is not possible.
								</Paragraph>
							</DoDont>
						</DoDont.Layout>
					</Accordion.Section>
					<Accordion.Section title="Tables" fullWidth>
						<Header.H4>Column headers</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: columnHeaderYes,
									alt: "A table with visible labels in each column header.",
								}}
							>
								<Paragraph>Try to put a visible label in each column header.</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: columnHeaderNo,
									alt: "A table that's missing a visible label in a column header.",
								}}
							>
								<Paragraph>
									Omit visible column header labels for aesthetic reasons. Only omit them when every
									table cell in a column contains an interactive element.
								</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Interactive content</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: interactiveContentYes,
									alt: "A table in which the last two columns' cells each contain a single button.",
								}}
							>
								<Paragraph>
									Put only a single interactive element, such as a{" "}
									<GatsbyLink to="/lib/components/Actions and controls/Button">Button</GatsbyLink>,
									in a table cell.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: interactiveContentNo,
									alt: "A table in which the last column's cells each contain two buttons.",
								}}
							>
								<Paragraph>
									Put multiple interactive elements components in a table cell. Instead, put them in
									separate table cells in separate table columns.
								</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Static content</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: staticContentYes,
									alt: "A table in which each cell contains a single piece of text.",
								}}
							>
								<Paragraph>
									Try to put only a single static element, such as a piece of text, in a table cell.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: staticContentNo,
									alt: "A table in which the first column's cells all contain two pieces of text.",
								}}
							>
								<Paragraph>
									Put multiple static elements in a table cell, unless absolutely necessary. Always
									try splitting up such content into multiple columns.
								</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>No data</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: noDataYes,
									alt: "A table with content in each cell.",
								}}
							>
								<Paragraph>
									Always put something in a table cell. When there’s no data for a table cell, put
									in an explanation for why this is the case.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: noDataNo,
									alt: "A table with multiple empty cells.",
								}}
							>
								<Paragraph>
									Leave table cells empty or filled with only a symbol for missing data, such as a
									dash or icon.
								</Paragraph>
							</DoDont>
						</DoDont.Layout>
					</Accordion.Section>
					<Accordion.Section title="Forms" fullWidth>
						<Header.H4>Visible input labels</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: visibleLabelsYes,
									alt: "A group of radio buttons with a visible label.",
								}}
							>
								<Paragraph>Add visible labels to input elements.</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: visibleLabelsNo,
									alt: "A group of radio buttons without a visible label",
								}}
							>
								<Paragraph>Omit visible labels from input elements.</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Placeholders</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: placeholdersYes,
									alt: "An input field for entering one's password. A required minimum number of characters is shown above the input field.",
								}}
							>
								<Paragraph>
									Use a visible label or help text for information essential to filling out an input
									field.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: placeholdersNo,
									alt: "An input field for entering one's password. The input field's placeholder indicates the required minimum number of characters.",
								}}
							>
								<Paragraph>Use placeholder text for essential information.</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Required format</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: requiredFormatYes,
									alt: "An input field for entering one's birthday. Information about the required date format (dd/mm/yyyy) is shown above the input field.",
								}}
							>
								<Paragraph>
									Provide instructions when an input requires a specific format (date, zip code,
									phone number, etc.)
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: requiredFormatNo,
									alt: "An input field for entering one's birthday. Information about the required date format is missing.",
								}}
							>
								<Paragraph>Assume users will know the correct input format.</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Multiple pages</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: multiplePagesYes,
									alt: "A page that is part of a multi-page form. A heading indicates the user is currently on step 2 of 5.",
								}}
							>
								<Paragraph>
									Inform users about their progress when a form is divided into multiple pages.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: multiplePagesNo,
									alt: "A page that is part of a multi-page form. No information is provided about the user's progress, besides buttons for navigating to a previous or next step.",
								}}
							>
								<Paragraph>Only show users whether there’s a previous and/or next step.</Paragraph>
							</DoDont>
						</DoDont.Layout>
					</Accordion.Section>
					<Accordion.Section title="Icons" fullWidth>
						<Header.H4>General usage</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: generalUsageYes,
									alt: 'A toggle group consisting of two buttons. On is labelled "Bar chart", the other "Line chart". Both have icons depicting their respective chart types.',
								}}
							>
								<Paragraph>
									Use icons purposefully to clarify the meaning of UI elements or improve
									scannability.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: generalUsageNo,
									alt: "A toggle group consisting of two unlabelled buttons. One has a bar chart icon, the other has a line chart icon.",
								}}
							>
								<Paragraph>Use icons mainly for aesthetic reasons.</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Visible icon labels</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: labelsYes,
									alt: 'A button labelled "Edit item" with an icon of a pencil.',
								}}
							>
								<Paragraph>Always try to add visible labels to icons.</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: labelsNo,
									alt: "An unlabelled button with an icon of a pencil.",
								}}
							>
								<Paragraph>Omit visible labels due to aesthetic reasons.</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Tooltip</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: tooltipYes,
									alt: 'A button with a plus icon. It has a tooltip that says "Add item".',
								}}
							>
								<Paragraph>
									Add a tooltip to an icon if there’s insufficient space for a visible label.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: tooltipNo,
									alt: 'A button with a plus icon. It has a label and tooltip that both say "Add item".',
								}}
							>
								<Paragraph>Add a tooltip if an icon has a visible label.</Paragraph>
							</DoDont>
						</DoDont.Layout>
					</Accordion.Section>
					<Accordion.Section title="Animations" fullWidth>
						<Header.H4>Flashing animations</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: flashingAnimationsYes,
									alt: "Three overlapping circles, symbolizing a non-flashing animation.",
								}}
							>
								<Paragraph>Avoid flashing animations as much as possible.</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: flashingAnimationsNo,
									alt: "Three overlapping circles with lightning bolts, symbolizing a flashing animation.",
								}}
							>
								<Paragraph>
									Let animations flash more than 3 times, and never more than 3 times per second.
								</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Static alternatives</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: staticAlternativesYes,
									alt: "A button in a loading state with a timeglass icon.",
								}}
							>
								<Paragraph>
									Provide developers with a static alternative (image) for your animation, since
									users can disable animations.
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: staticAlternativesNo,
									alt: "A button in a loading state with a static loading spinner in the shape of a circle.",
								}}
							>
								<Paragraph>
									Always use a single frame from your animation as static alternative. Users may
									struggle to understand the meaning of such an image.
								</Paragraph>
							</DoDont>
						</DoDont.Layout>
						<Header.H4>Controls</Header.H4>
						<DoDont.Layout>
							<DoDont
								variant="doThis"
								exampleImage={{
									src: controlsYes,
									alt: "A video with a hovering mouse cursor and a pause button.",
								}}
							>
								<Paragraph>
									Provide controls to pause, stop, or hide videos or auto-updating content
									(auto-scrolling feeds, image carousels, GIFs, etc.).
								</Paragraph>
							</DoDont>
							<DoDont
								variant="dontDoThis"
								exampleImage={{
									src: controlsNo,
									alt: "A video with a hovering mouse cursor, but no controls to pause, stop, or hide the video.",
								}}
							>
								<Paragraph>Omit such controls.</Paragraph>
							</DoDont>
						</DoDont.Layout>
					</Accordion.Section>
				</Accordion>
			</TextContainer>
		</ContentSection>
	</>
);
